
import { defineComponent, PropType } from 'vue';
import { getImageUrl } from '@/helpers/getImageUrl';
import type { LetterData } from './types';

/**
 * NOTE: При изменении шаблона письма, необходимо также обновить соотв-й html-шаблон
 * в директории /letters и передать его на бэкенд.
 * Комментарии в макете - заготовки для формирования erb-шаблона.
 */
export default defineComponent({
  name: 'LetterPreview',

  props: {
    letterData: {
      type: Object as PropType<LetterData>,
      required: true,
    },
  },

  computed: {
    imageSrc(): string {
      if (!this.letterData.coverLetter) {
        return '';
      }

      return getImageUrl({
        uuid: this.letterData.coverLetter,
        type: 'survey',
        name: 'cover_letter',
        size: null,
      });
    },
  },
});
