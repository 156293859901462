
import Vue from 'vue';
import LetterPreview from '@/components/assessmentSurvey/LetterPreview.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { tsxassApi } from '@/services/api';
import { V1EntitiesInviteesPublicLetter } from '@/services/api/tsxass';
import { LetterData } from '@/components/assessmentSurvey/types';
import errorHandler from '@/helpers/errorHandler';
import toCamelCase from '@/utils/transformers/toCamelCase';

export default Vue.extend({
  name: 'ExternalExpertLetterView',

  components: {
    TTLoader,
    LetterPreview,
  },

  inject: ['RouteNames'],

  data() {
    return {
      letterData: null as V1EntitiesInviteesPublicLetter | null,
      loading: false,
    };
  },

  computed: {
    expertListId(): number {
      return Number(this.$route.query.expertListId) || 0;
    },
    letterFieldValues(): LetterData | null {
      if (!this.letterData) {
        return null;
      }
      const fieldsMap = Object.fromEntries(this.letterData.args.map((item) => ([item.key, item.value])));
      return toCamelCase(fieldsMap);
    },
  },

  async created() {
    if (this.expertListId) {
      await this.loadLetterData();
    } else {
      await this.$router.push({ name: this.RouteNames.R_APP_HOME });
    }
  },

  methods: {
    async loadLetterData() {
      if (!this.expertListId) return;
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1InviteesLetter(this.expertListId);
        this.letterData = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

  },
});
